<template>
	<UnorderedList
		v-if="showMultipleLines"
		:items="messagesList"
		:class="'m-0 space-y-1'"
		:items-padding-y="'pt-0'"
		:icon-top="'top-1'"
		:icon-gap="'pl-5'"
		:show-list-icon="showIcon"
	>
		<template #icon>
			<IconTimes v-if="isErrorIcon" class="h-auto w-3 text-red-400" />
			<IconTick v-if="!isErrorIcon" class="h-auto w-3 text-emerald-700" />
		</template>
	</UnorderedList>
	<span v-else class="flex items-center gap-2">
		<IconTimes v-if="isErrorIcon" class="h-auto w-3 text-red-400" />
		<IconTick v-if="!isErrorIcon" class="h-auto w-3 text-emerald-700" />
		{{ firstMessageOnly }}
	</span>
</template>

<script setup>
import UnorderedList from '@/components/lists/UnorderedList.vue'
import IconTimes from '@/assets/svg/common/ico-times.svg'
import IconTick from '@/assets/svg/common/ico-tick.svg'

const props = defineProps({
	messages: {
		type: Object,
		required: true,
	},
	showIcon: {
		type: Boolean,
		default: true,
		required: false,
	},
	iconColor: {
		type: String,
		default: 'red',
		validator: (value) => ['red', 'green'].includes(value),
		required: false,
	},
	type: {
		type: String,
		default: 'multi',
		validator: (value) => ['multi', 'single'].includes(value),
		required: false,
	},
})

const showMultipleLines = computed(() => props.type === 'multi')
const isErrorIcon = computed(() => props.iconColor === 'red')

const messagesList = Object.values(props.messages).flatMap((errorArray) =>
	Array.isArray(errorArray)
		? errorArray.map((errorMessage) => ({ label: errorMessage }))
		: [{ label: errorArray }]
)

const firstMessageOnly = messagesList[0]?.label
</script>
