<template>
	<div
		class="relative mt-2 rounded border px-4 py-3"
		:class="[
			isAlert
				? 'border-red-200 bg-red-50'
				: 'border-green-200 bg-green-50 text-emerald-700',
		]"
		role="alert"
	>
		<slot></slot>
	</div>
</template>

<script setup>
const props = defineProps({
	messagePosition: {
		type: String,
		default: 'flex justify-left',
		required: false,
	},
	type: {
		type: String,
		default: 'red',
		validator: (value) => ['red', 'green'].includes(value),
		required: false,
	},
})

const isAlert = computed(() => props.type === 'red')
</script>
